export const environment = {
  ENV_MODE: 'develop',
  SHADOW: 'false',
  ENDPOINT_V1: 'https://engine-k8s-test.solar.com/symfony/api/',
  ENDPOINT_V2: 'https://engine-k8s-test.solar.com/symfony/v2/',
  ENDPOINT_V21: 'https://engine-k8s-test.solar.com/symfony/v21/',
  ENDPOINT_INC: 'https://engine-k8s-test.solar.com/',
  ENDPOINT_INJ: 'https://engine-k8s-test.solar.com/injection/',
  ENDPOINT_INJ_V3: 'https://engine-k8s-test.solar.com/injection/v3/',
  CDN_URL: 'https://assets.solar.com',
  MAPS_API_KEY: 'AIzaSyAAcvldG0koZjnEalZR38hrMHZXIyWPGjA',
  SOURCE_FILES: 'https://test-source.solar.com/',
  DOWNTIME_FILES: 'https://downtime-interface-prelive.electrum.co/',
  UTILITY_URL: 'https://utilityapi.com/authorize/pickmysolar?demo',
  ENDPOINT_ANALYTICS: 'https://engine-k8s-test.solar.com/project/api/',
  STRIPE_PUBLIC_KEY: 'pk_test_51KrVVKDSpzT48Zfhhn0v0Hh6U8OMPrinMCLrGJQoLfGPL4CBOmDB7riWRb20Car7iCdwXSowMPpGuvykw0aHd1Kt002FZ9EChb',
  PDF_URL: 'https://files-prelive.solar.com/',
  PUSHER_APP_KEY: 'd6f21489484ae902dcd4',
  PUSHER_CLUSTER: 'mt1',
};