// @ts-nocheck
import { EclipseIFrame } from './iframe';
import { InjectionV2 } from './injection-v2';
import {
  IEclipseContainerRef,
  IEclipseConfig,
  IEventInfo,
  IRequiredEclipseConfig,
} from './interface/injection.interface';
import { EclipseShadowDom } from './shadow-dom';
import { Utils } from './utils';

export class EclipseInjection {
  config: IEclipseConfig = {
    assets: [],
    mode: 'iframe',
    partnerId: '',
    products: [],
    routerEnabled: true,
    routerIdentifier: null,
  };
  initV2 = false;
  private wrapperRef!: IEclipseContainerRef;

  constructor() {
    //
  }
  public init(config: IRequiredEclipseConfig): void {
    this.config = { ...this.config, ...config };
    if(!this.checkInit()) return;
    switch (this.config?.mode) {
      case 'iframe': {
        this.initIFrame();
        break;
      }
      case 'shadow': {
        this.initShadow();
        break;
      }
      default: {
        this.initIFrame();
      }
    }
    this.on('init-v2', event => {
      this.resetInjection();
      this.initInjectionV2(event);
    });
  }
  public on(type: string, callBack: (event: IEventInfo) => void): void {
    this.wrapperRef.on(type, callBack);
  }
  public post(message: IEventInfo): void {
    this.wrapperRef.post(message);
  }
  public reInit(): void {
    this.resetInjection();
    Utils.delay(100).then(() => {
      this.init(this.config);
    });
  }
  private initIFrame(): void {
    this.wrapperRef = new EclipseIFrame(this.config);
    this.initV2 = false;
  }
  private initShadow(): void {
    this.wrapperRef = new EclipseShadowDom(this.config);
    this.initV2 = false;
  }
  private initInjectionV2(event): void {
    this.wrapperRef = new InjectionV2(this.config, event);
    this.initV2 = true;
  }
  private resetInjection() {
    const eclipseElement = window.document.getElementsByTagName('eclipse-wrapper')[0];
    eclipseElement?.style.display = 'none';
    setTimeout(() => {
      eclipseElement.remove();
    }, 1000);
  }
  public reset(): void {
    this.resetInjection();
  }
  private checkInit(): boolean {
    if(this.config.routerEnabled && this.config.routerIdentifier) {
      if(window.location.pathname.search(`/${this.config.routerIdentifier}`) === -1) return false;
    }
    return true;
  }
}
